import React from 'react';
import { useInView } from 'react-intersection-observer';

const InfoCard = ({ title, description, buttonLabel, buttonLink, image }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  const animationClasses = inView ? 'animate-fadeInUp opacity-100' : 'opacity-0';

  return (
    <div 
      ref={ref} 
      className={`flex flex-col md:flex-row bg-white rounded-lg overflow-hidden mb-10 transition-all duration-500 ${animationClasses}`}
      style={{ boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}
    >
      <div className="w-full md:w-1/2">
        <img src={image} alt={`${title} image`} className="mx-auto object-cover w-4/5 h-full mt-10" />
      </div>
      <div className="w-full md:w-1/2 p-8 flex flex-col justify-center mt-4 md:mt-0">
        <h3 className="text-2xl font-bold mb-4 text-center">{title}</h3>
        <p className="mb-8 text-center">{description}</p>
        {buttonLabel && buttonLink && (
          <a 
            href={buttonLink} 
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto"
            style={{ width: '256px' }}
          >
            {buttonLabel}
          </a>
        )}
      </div>
    </div>
  );
};

export default InfoCard;
