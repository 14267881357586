import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import yourLogo from '../../../assets/images/cc_new_logo.PNG';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 md:grid-cols-3 gap-8">

        {/* Left Section */}
        <div className="flex flex-col space-y-4">
          <img src={yourLogo} alt="Logo" className="w-20 h-20" />
          <p className="text-sm">Connecting students and colleges for a brighter future.</p>
          <div className="flex items-center space-x-2">
            {[
              { icon: faLinkedin, link: 'https://www.linkedin.com/company/college-connector/' }
            ].map(({ icon, link }) => (
              <a key={link} href={link} className="text-white hover:text-gray-400 transition duration-300">
                <FontAwesomeIcon icon={icon} />
              </a>
            ))}
          </div>
        </div>

        {/* Middle Section */}
        <div className="space-y-4">
          <h3 className="text-lg font-bold">Contact Us</h3>
          <p>
            Email: <a href="mailto:CollegeConnectorNH@gmail.com">CollegeConnectorNH@gmail.com</a>
          </p>
        </div>

        {/* Right Section */}
        <div className="flex flex-col space-y-4">
          <h3 className="text-lg font-bold">Explore</h3>
          <ul className="space-y-2">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/ourteam">Our Team</Link></li>
            <li><Link to="/press">Press</Link></li>
          </ul>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="mt-8 border-t border-gray-700 pt-6 text-center">
        <p> College Connector Network LLC, {new Date().getFullYear()}</p>
      </div>
    </footer>
  );
}

export default Footer;
