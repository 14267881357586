import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

const PressCard = ({ title, description, buttonLabel, buttonLink, image, webLink, reverse }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  const layoutClasses = reverse ? 'flex-row-reverse' : 'flex-row';
  const animationClasses = inView ? 'animate-fadeInUp opacity-100' : 'opacity-0';

  return (
    <div 
      ref={ref} 
      className={`flex flex-col md:${layoutClasses} bg-white rounded-lg overflow-hidden mb-10 transition-all duration-500 ${animationClasses}`}
      style={{ boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}
    >
      <div className="w-full">
        <img src={image} alt={title} className="mx-auto object-cover w-4/5 h-full mt-10" />
      </div>
      <div className="w-7/8 mx-auto p-8 flex flex-col justify-center text-center"> 
         <h3 className="text-2xl mx-auto font-bold mt-8 mb-4">{title}</h3>
         <p className="mb-8">{description}</p>
         <a 
            href={webLink} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            style={{ width: '256px', margin: '0 auto' }} // Set the width here
         >
            Read More
         </a>
      </div>
    </div>
  );
};

export default PressCard;
