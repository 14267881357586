import React from 'react';
import ProductCard from './../ProductCard/ProductCard';

// Placeholder product data
const placeholderProducts = [
  {
    id: 1,
    name: 'Business Essentials: Foundations of Management',
    price: '60',
    description: 'Authored by John Doe, a renowned business strategist, this textbook covers the essential principles of management.',
    rating: 4.7,
    image: 'https://via.placeholder.com/400x400?text=Business+Essentials',
  },
  {
    id: 2,
    name: 'Strategic Marketing: Creating Competitive Advantage',
    price: '75',
    description: 'Written by Jane Smith, a leading marketing expert, this book delves into strategic marketing concepts for achieving competitive advantage.',
    rating: 4.9,
    image: 'https://via.placeholder.com/400x400?text=Strategic+Marketing',
  },
  {
    id: 3,
    name: 'Financial Management: Principles and Applications',
    price: '70',
    description: 'Authored by Michael Johnson, a world-renowned financial analyst, this textbook provides comprehensive coverage of financial management principles and applications.',
    rating: 4.8,
    image: 'https://via.placeholder.com/400x400?text=Financial+Management',
  },
  {
    id: 4,
    name: 'Artificial Intelligence: A Modern Approach',
    price: '85',
    description: 'Written by Stuart Russell and Peter Norvig, this comprehensive textbook provides an in-depth introduction to the field of artificial intelligence.',
    rating: 4.6,
    image: 'https://via.placeholder.com/400x400?text=Artificial+Intelligence',
  },
  {
    id: 5,
    name: 'Psychology: Themes and Variations',
    price: '55',
    description: 'Authored by Wayne Weiten, this bestselling textbook offers an engaging exploration of psychology, covering various themes and variations in the field.',
    rating: 4.5,
    image: 'https://via.placeholder.com/400x400?text=Psychology',
  },
  {
    id: 6,
    name: 'Data Structures and Algorithms',
    price: '65',
    description: 'Written by Thomas H. Cormen, Charles E. Leiserson, Ronald L. Rivest, and Clifford Stein, this textbook is a comprehensive guide to data structures and algorithms.',
    rating: 4.8,
    image: 'https://via.placeholder.com/400x400?text=Data+Structures',
  },
  {
    id: 7,
    name: 'The Elements of Style',
    price: '40',
    description: 'Authored by William Strunk Jr. and E. B. White, this classic reference book provides timeless advice on writing effectively and concisely.',
    rating: 4.7,
    image: 'https://via.placeholder.com/400x400?text=Elements+of+Style',
  },
  {
    id: 8,
    name: 'Microeconomics',
    price: '50',
    description: 'Authored by Paul Krugman and Robin Wells, this textbook offers a thorough exploration of microeconomic principles, theories, and applications.',
    rating: 4.6,
    image: 'https://via.placeholder.com/400x400?text=Microeconomics',
  },
  {
    id: 9,
    name: 'Organic Chemistry',
    price: '80',
    description: 'Written by David R. Klein, this textbook provides a clear and comprehensive introduction to organic chemistry, featuring extensive problem-solving exercises.',
    rating: 4.9,
    image: 'https://via.placeholder.com/400x400?text=Organic+Chemistry',
  },
  {
    id: 10,
    name: 'Introduction to Sociology',
    price: '45',
    description: 'Authored by Anthony Giddens, Mitchell Duneier, Richard P. Appelbaum, and Deborah Carr, this textbook offers an accessible introduction to the field of sociology, covering key concepts and theories.',
    rating: 4.5,
    image: 'https://via.placeholder.com/400x400?text=Sociology',
  },
];

function ProductGrid() {
  return (
    <div className='text-center'>
      <h2 className="text-3xl font-semibold mb-8 mt-10">Recently Listed:</h2>
      <div className="flex flex-wrap justify-center p-5">
        {placeholderProducts.map((product) => (
          <div key={product.id} className="flex-none m-4">
            <ProductCard product={product} />
          </div>
        ))}
      </div>
    </div>
  );
}


export default ProductGrid;
