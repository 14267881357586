import React from 'react';

const Waitlist = () => {
  return (
    <div>
      <iframe
        id="JotFormIFrame-241236322636148"
        title="Sign Up List"
        onLoad={() => window.parent.scrollTo(0,0)}
        allowtransparency="true"
        allow="geolocation; microphone; camera; fullscreen"
        src="https://form.jotform.com/241236322636148"
        frameBorder="0"
        style={{minWidth: '100%', maxWidth: '100%', height: '725px', border: 'none'}}
        scrolling="no"
      >
      </iframe>
      <div style={{height: '50px'}}></div> {/* Blank space below the form */}
      <script src="https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js"></script>
      <script>{`window.jotformEmbedHandler("iframe[id='JotFormIFrame-241236322636148']", "https://form.jotform.com/")`}</script>
    </div>
  );
};

export default Waitlist;
