// src/pages/ConnectPage.js
import React from 'react';
import HeroSection from '../components/Connect/HeroSection/HeroSection';
import ProductGrid from '../components/Connect/ProductGrid/ProductGrid';
import CollectionsGrid from '../components/Connect/CollectionsGrid/CollectionsGrid';
import Footer from '../components/Common/Footer/Footer';

const ConnectPage = () => {
  return (
    <div>
        <HeroSection />
        <CollectionsGrid />
        <ProductGrid />
        <Footer />
    </div>
  );
};

export default ConnectPage;
