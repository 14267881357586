import React from 'react';
import Footer from '../components/Common/Footer/Footer';
import Waitlist from '../components/DownloadNow/Waitlist';

const OurTeamPage = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-grow flex justify-center items-center" style={{ marginTop: '50px' }}> 
        <div className="text-center">
          <h1 className="text-4xl font-bold mb-4">Download Now</h1>
          <p className="text-lg mb-8">College Connector is currently under development and is expected to launch in September 2024. Join our mailing list to be notified when we deploy!</p>
          <Waitlist />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OurTeamPage;
