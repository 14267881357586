import React from 'react';
import TeamCard from './TeamCard';
import BrettImage from '../../assets/images/brett_linkedin.jpeg';
import SujoshImage from '../../assets/images/sujosh_linkedin.png';
import ElbertImage from '../../assets/images/elbert_linkedin.jpeg';
import FritzImage from '../../assets/images/fritz_linkedin.jpeg';

const teamMembers = [
  {
    title: "Brett Schultz, CEO",
    description: "Brett is a junior majoring in Finance, Marketing, and Sustainability. He serves as the company's entrepreneurial lead.", 
    linkedin: "https://www.linkedin.com/in/brettrschultz/",
    image: BrettImage,
    reverse: false,
  },
  {
    title: "Elbert Tulung, CTO",
    description: "Elbert is a sophomore pursuing a degree in Computer Science. He is the team's primary back-end developer.",
    linkedin: "https://www.linkedin.com/in/elbert-tulung/",
    image: ElbertImage,
    reverse: false,
  },
  {
    title: "Fritzgerald Aristor, COO",
    description: "Fritzgerald is a sophomore student pursuing a degree in Electrical Engineering. He is the team's primary front-end developer.",
    linkedin: "https://www.linkedin.com/in/fritzgeraldaristor/",
    image: FritzImage,
    reverse: false,
  }
];

const TeamSection = () => {
  return (
    <div className="team-section container mx-auto py-12">
      <h2 className="text-4xl font-bold text-center mb-12">Our Team</h2>
      <div className="flex flex-wrap justify-center">
        {teamMembers.map((member, index) => (
          <TeamCard 
            key={index}
            title={member.title}
            description={member.description}
            linkedin={member.linkedin}
            image={member.image}
            reverse={member.reverse}
          />
        ))}
      </div>
    </div>
  );
};

export default TeamSection;
