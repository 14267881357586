import { createClient } from '@supabase/supabase-js';
// Uncomment this if you are in a React Native environment
// import AsyncStorage from '@react-native-async-storage/async-storage';

const SUPABASE_URL = 'https://fjtuhbfayhuvrrlhktpa.supabase.co';
const SUPABASE_ANON_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZqdHVoYmZheWh1dnJybGhrdHBhIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjEzOTg5MTcsImV4cCI6MjAzNjk3NDkxN30.K_9t4cIwdfYQqFsrXknalMPmbFZwihEhuI_Csb3P1BA';

// Create the Supabase client
const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY, {
  auth: {
    // Only use this if you're in a React Native environment
    // storage: AsyncStorage,
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false,
  },
});

export { supabase };
