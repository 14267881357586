import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../components/Common/Footer/Footer';
import { supabase } from '../services/supabaseClient'; // Ensure you have the Supabase client imported

const EmailConfirmationPage = () => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        // Extract the confirmation URL
        const params = new URLSearchParams(location.search);
        const confirmationUrl = params.get('confirmation_url');

        if (!confirmationUrl) {
          throw new Error('Invalid confirmation URL');
        }

        // Decode the confirmation URL
        const decodedUrl = decodeURIComponent(confirmationUrl);
        const urlParams = new URLSearchParams(new URL(decodedUrl).search);
        const token = urlParams.get('token');
        const type = urlParams.get('type');

        if (!token || type !== 'signup') {
          throw new Error('Invalid confirmation link');
        }

        // Call Supabase API to confirm the email using the token
        const { error } = await supabase.auth.verifyOTP({
          token,
          type: 'signup',
        });

        if (error) {
          throw new Error(error.message);
        }

        // If all checks pass, set confirmation success
        setIsConfirmed(true);
      } catch (error) {
        setError(error.message);
      }
    };

    confirmEmail();
  }, [location]);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <div className="container mx-auto px-4 py-12 sm:px-6 lg:px-8">
          <div className="bg-white shadow-md rounded-lg p-6 max-w-md mx-auto">
            <h1 className="text-2xl font-bold mb-4 text-center">Email Confirmation</h1>
            {isConfirmed ? (
              <p className="text-green-600 text-center">Your email has been confirmed successfully!</p>
            ) : (
              <p className="text-center">Verifying your email, please wait...</p>
            )}
            {error && <p className="text-red-600 mt-4 text-center">{error}</p>}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EmailConfirmationPage;
