// src/pages/MarketplacePage.js
import React from 'react';
import Sidebar from '../components/Marketplace/Sidebar/Sidebar';
import HeroSection from '../components/Marketplace/HeroSection/HeroSection';
import ProductGrid from '../components/Marketplace/ProductGrid/ProductGrid';
import CollectionsGrid from '../components/Marketplace/CollectionsGrid/CollectionsGrid';
import Footer from '../components/Common/Footer/Footer';

const MarketplacePage = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar should start below the Header; adjust 'top-16' to match your Header's height */}
        <div className="fixed top-16 bottom-0" style={{  width: '25vw' }}>
          <Sidebar />
        </div>
        {/* Main content adjusted to take up the remaining space; 'ml-[25%]' offsets the sidebar */}
        <main className="flex-1 ml-[25vw]" style={{  width: '75vw' }}>
          <HeroSection />
          <CollectionsGrid />
          <ProductGrid />
          
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default MarketplacePage;
