import React, { useState } from 'react';
import {
  CogIcon,
  HomeIcon,
  BellIcon,
  InboxIcon,
  ShoppingBagIcon,
  TagIcon,
  MagnifyingGlassIcon,
  TruckIcon
} from '@heroicons/react/24/outline';

function Sidebar() {
  return (
    <aside className={`bg-gray-50 min-h-screen p-5 fixed inset-y-0 left-0 border-r pt-28`} style={{  width: '25vw' }}>
      <div className="px-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold">Marketplace</h1>
        <button className="p-2 rounded-full hover:bg-gray-200 transition duration-300">
          <CogIcon className="h-6 w-6" />
        </button>
      </div>
      {/* Search bar */}
      <div className="mt-3 mb-5 flex">
        <div className="flex w-full bg-white p-2 border border-gray-300 rounded">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-500 mr-3" />
          <input
            className="w-full outline-none"
            type="search"
            placeholder="Search Marketplace"
          />
        </div>
      </div>

      {/* Navigational Links */}
      <div className="flex flex-col space-y-1">
        {/* Add hover:bg-blue-100 to each button and bg-blue-500 to icon when hovering */}
        <button className="flex items-center p-2 rounded-md hover:bg-blue-100 transition duration-300">
          <HomeIcon className="h-6 w-6 text-gray-600 mr-2 transition-colors duration-200 group-hover:bg-blue-500 group-hover:text-white rounded-full"/>
          Browse all
        </button>
        <button className="flex items-center p-2 rounded-md hover:bg-blue-100 transition duration-300">
          <BellIcon className="h-6 w-6 text-gray-600 mr-2 transition-colors duration-200 group-hover:bg-blue-500 group-hover:text-white rounded-full"/>
          Notifications
        </button>
        <button className="flex items-center p-2 rounded-md hover:bg-blue-100 transition duration-300">
          <InboxIcon className="h-6 w-6 text-gray-600 mr-2 transition-colors duration-200 group-hover:bg-blue-500 group-hover:text-white rounded-full"/>
          Inbox
        </button>
        <button className="flex items-center p-2 rounded-md hover:bg-blue-100 transition duration-300">
          <ShoppingBagIcon className="h-6 w-6 text-gray-600 mr-2 transition-colors duration-200 group-hover:bg-blue-500 group-hover:text-white rounded-full"/>
          Buying
        </button>
        <button className="flex items-center p-2 rounded-md hover:bg-blue-100 transition duration-300">
          <TagIcon className="h-6 w-6 text-gray-600 mr-2 transition-colors duration-200 group-hover:bg-blue-500 group-hover:text-white rounded-full"/>
          Selling
        </button>
      </div>

      {/* Create new listing button */}
      <button className="bg-blue-600 text-white p-2 rounded-md hover:bg-blue-700 transition duration-300 w-full mt-4">
        + Create new listing
      </button>

      <hr className="my-4"/> {/* Divider */}

      {/* Categories */}
      <div>
        <h5 className="font-bold mb-2">Categories</h5>
        {/* Categories with dividers */}
        <button className="text-left text-gray-700 hover:bg-gray-100 w-full text-lg flex items-center p-2 rounded-md transition duration-300">
          <TruckIcon className="h-6 w-6 text-gray-600 mr-2"/>
          Textbooks
        </button>
        <hr className="my-4"/> {/* Divider */}
        <button className="text-left text-gray-700 hover:bg-gray-100 w-full text-lg flex items-center p-2 rounded-md transition duration-300">
          <HomeIcon className="h-6 w-6 text-gray-600 mr-2"/>
          Furniture
        </button>
        <hr className="my-4"/> {/* Divider */}
        <button className="text-left text-gray-700 hover:bg-gray-100 w-full text-lg flex items-center p-2 rounded-md transition duration-300">
          <TagIcon className="h-6 w-6 text-gray-600 mr-2"/>
          Cool Finds
        </button>
        {/* Add more categories as needed */}
      </div>
    </aside>
  );
}

export default Sidebar;
