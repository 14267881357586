import React from 'react';

// Placeholder collections data
const placeholderCollections = [
  {
    id: 'freestyle',
    title: 'Freestyle Collection',
    image: 'https://via.placeholder.com/400x400?text=Freestyle+Collection',
  },
  {
    id: 'backcountry',
    title: 'Backcountry Collection',
    image: 'https://via.placeholder.com/400x400?text=Backcountry+Collection',
  },
  {
    id: 'thermals',
    title: 'Thermals Collection',
    image: 'https://via.placeholder.com/400x400?text=Thermals+Collection',
  },
  // Add more collections as needed
];

function CollectionsGrid() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
      {placeholderCollections.map((collection) => (
        <div key={collection.id} className="rounded-lg overflow-hidden shadow-lg">
          <img
            src={collection.image}
            alt={collection.title}
            className="w-full h-64 object-cover transition-transform duration-300 transform hover:scale-105"
          />
          <div className="bg-white p-4">
            <h3 className="text-lg font-semibold mb-2">{collection.title}</h3>
            <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300">
              Explore Collection
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CollectionsGrid;
