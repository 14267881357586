// src/components/HeroSection.js
import React, { useState, useEffect } from 'react';
import transition1 from '../../../assets/images/transaction_photo_one.png'
import transition2 from '../../../assets/images/transaction_photo_two.png'
import transition3 from '../../../assets/images/transaction_photo_three.jpg'

const heroImages = [
  { url: transition1, alt: 'Description of first image' },
  { url: transition2, alt: 'Description of second image' },
  { url: transition3, alt: 'Description of third image' },
];

function HeroSection() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % heroImages.length);
    }, 6000); // Change slide every 3000ms (3 seconds)

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="relative w-full overflow-hidden" style={{ height: '66.6667vh' }}> {/* Apply height directly */}
      {heroImages.map((image, index) => (
        <img 
          key={index} 
          src={image.url} 
          alt={image.alt} 
          className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-1000 ${index === currentSlide ? 'opacity-100' : 'opacity-0'}`} 
        />
      ))}
      <div className="absolute inset-0 bg-black opacity-50"></div> {/* Optional overlay */}
      <div className="absolute w-full h-full flex items-center justify-center text-center text-white p-4">
        <div>
          <h1 className="text-5xl font-bold mb-4">Welcome to Our Connect</h1>
          <p className="text-xl mb-8">This page will soon be deleted as the Connect Feature is currently under construction. Stay tuned!</p>
          <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Shop Now
          </button>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
