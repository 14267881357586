import React, { useState, useEffect } from 'react';
import { supabase } from '../services/supabaseClient';

const ForgotPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState(''); // Add OTP state
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handlePasswordReset = async () => {
    console.log("Reset button clicked.");
    console.log("New password input:", newPassword);
    console.log("OTP being used:", otp);
    console.log("Email being used:", email); // Log email

    if (!newPassword || !otp || !email) {
      setError('Missing required fields.');
      console.log("Error: Missing fields", { newPassword, otp, email });
      return;
    }

    try {
      console.log("Verifying OTP...");

      // Verify the OTP and email address
      const { data, error: verifyError } = await supabase.auth.verifyOtp({
        type: 'recovery', // Specify that this is a recovery OTP
        token: otp, // Use OTP instead of the token
        email: email, // Include the user's email address
      });

      if (verifyError) {
        setError('OTP verification failed.');
        console.log("OTP verification error:", verifyError);
        return;
      }

      console.log("OTP verified successfully. Session data:", data);

      // Use the session created after verification to update the password
      const { error: updateError } = await supabase.auth.updateUser({
        password: newPassword,
      });

      if (updateError) {
        setError(updateError.message);
        console.log("Password update error:", updateError);
      } else {
        setSuccess('Password reset successful. You can now log in.');
        console.log("Password reset successful.");
      }
    } catch (err) {
      setError('Unexpected error occurred. Please try again.');
      console.log("Unexpected error:", err);
    }
  };

  return (
    <div style={styles.container}>
      <h1>Reset Your Password</h1>

      {error && <p style={styles.error}>{error}</p>}
      {success && <p style={styles.success}>{success}</p>}

      <input
        type="email"
        style={styles.input}
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <input
        type="text"
        style={styles.input}
        placeholder="Enter OTP"
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
      />

      <input
        type="password"
        style={styles.input}
        placeholder="New Password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />

      <button style={styles.button} onClick={handlePasswordReset}>
        Confirm Password Reset
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
  },
  error: {
    color: 'red',
    marginBottom: '10px',
    fontSize: '16px',
  },
  success: {
    color: 'green',
    marginBottom: '10px',
    fontSize: '16px',
  },
  input: {
    width: '100%',
    height: '50px',
    padding: '10px',
    marginBottom: '20px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  button: {
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '15px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default ForgotPassword;
