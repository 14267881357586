import React from 'react';
import Footer from '../components/Common/Footer/Footer';
import { CSSTransition } from 'react-transition-group';

const HelpPage = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <CSSTransition
        in={true}
        appear={true}
        timeout={500}
        classNames={{
          appear: 'opacity-0',
          appearActive: 'opacity-100 transition-opacity duration-500',
        }}
      >
        <div className="flex-grow flex flex-col justify-center items-center text-center p-6" style={{ marginTop: '50px' }}>
          <h1 className="text-3xl font-bold mb-4">Need Help?</h1>
          <p className="text-lg mb-6">
            For issues accessing or using our app, please contact us at
            <a href="mailto:collegeconnectornh@gmail.com" className="text-blue-500 hover:underline ml-1">
              collegeconnectornh@gmail.com
            </a>
          </p>
        </div>
      </CSSTransition>
      <Footer />
    </div>
  );
};

export default HelpPage;
