import React from 'react';

function ProductCard({ product }) {
  return (
    <div className="border rounded-lg overflow-hidden shadow-lg flex flex-col mr-4"style={{ 'width': '30vw' }} >
      <div className='mx-auto mt-5' style={{ 'width': '80%' }}>
        <img src={product.image} alt={product.name} className="w-5/6 h-63 object-cover mx-auto" />
        <div className="p-4 flex-grow">
          <h3 className="text-lg font-bold mb-5 mx-auto">{product.name}</h3>
          <p className="text-gray-500 mx-auto">${product.price}</p>
          <div className="flex items-center mt-5 mx-auto">
            <div className="flex text-yellow-500 mx-auto">
              {[...Array(5)].map((_, index) => (
                <svg
                  key={index}
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-5 w-5 ${index < Math.round(product.rating) ? 'text-yellow-500' : 'text-gray-300'}`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 1a.75.75 0 01.675.408l1.654 3.388 3.693.537a.75.75 0 01.416 1.279l-2.674 2.608.632 3.683a.75.75 0 01-1.088.791L10 13.392l-3.307 1.74a.75.75 0 01-1.088-.79l.632-3.684-2.674-2.608a.75.75 0 01.416-1.28l3.693-.537 1.654-3.388A.75.75 0 0110 1zm0 2.445L8.615 5.616a.75.75 0 01-.564.41l-3.24.47 2.346 2.288a.75.75 0 01.216.664l-.554 3.23 2.889-1.519a.75.75 0 01.698 0l2.89 1.519-.555-3.23a.75.75 0 01.216-.664l2.346-2.288-3.24-.47a.75.75 0 01-.564-.41L10 3.446V3.446z"
                    clipRule="evenodd"
                  />
                </svg>
              ))}
            </div>
            <span className="text-gray-700 ml-1 mx-auto">{product.rating}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
