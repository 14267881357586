import React from 'react';
import InfoCard from './InfoCard';
import PhotoOne from '../../../assets/images/transaction_photo_one.png';
import PhotoTwo from '../../../assets/images/transaction_photo_two.png';

const infoFeatures = [
  {
    title: "Connect Feature",
    description: "Find out about opportunities, clubs, and events that match your interests and meet like-minded students.",
    image: PhotoOne,
    reverse: false,
  },
  {
    title: "Marketplace Feature",
    description: <>Exchange textbooks, dorm furniture, and other items with students on your campus, and earn some cash in the process. <strong>Coming soon!</strong></>,
    image: PhotoTwo,
    reverse: true,
  },
];

const InfoSection = () => {
  return (
    <div className="info-section container mx-auto py-12">
      <h2 className="text-4xl font-bold text-center mb-12">Features</h2>
      <div className="flex flex-wrap justify-center">
        {infoFeatures.map((feature, index) => (
          <InfoCard 
            key={index}
            title={feature.title}
            description={feature.description}
            image={feature.image}
            reverse={feature.reverse}
          />
        ))}
      </div>
    </div>
  );
};

export default InfoSection;
