import React from 'react';
import { Link } from 'react-router-dom';
import { UserCircleIcon, BellIcon, GlobeAltIcon, ShoppingCartIcon } from '@heroicons/react/24/outline';
import yourLogo from '../../../assets/images/cc_new_logo.PNG';

function Header() {
  return (
    <header className="bg-gray-900 sticky top-0 z-50 border-gray-900 py-4 shadow-md" style={{ width: '100vw' }}>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">

        {/* Logo */}
        <Link to="/" className="flex items-center space-x-2 text-xl font-bold text-white">
          <img src={yourLogo} alt="College Connector Logo" className="h-10 w-auto" /> {/* Adjusted logo size */}
          <span className="hidden sm:inline text-base">College Connector</span> {/* Hide the full text on small screens */}
        </Link>

        {/* User Actions */}
        <div className="flex items-center space-x-4">
          <Link to="/DownloadNow">
            <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-3 sm:px-4 rounded-md text-sm sm:text-base">
              Download Now
            </button>
          </Link>
        </div>

      </div>
    </header>
  );
}

export default Header;
