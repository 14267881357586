// src/components/ProductGrid.js
import React from 'react';
import ProductCard from './../ProductCard/ProductCard';

// Placeholder product data
const placeholderProducts = [
  {
    id: 1,
    name: 'Textbook',
    price: '60',
    image: 'https://via.placeholder.com/200x200?text=Product+1', // Placeholder image URL
  },
  {
    id: 2,
    name: 'Textbook',
    price: '75',
    image: 'https://via.placeholder.com/200x200?text=Product+2',
  },
  {
    id: 3,
    name: 'Textbook',
    price: '70',
    image: '../images/textbook.jpg',
  },
  // Add more products as needed
];

function ProductGrid() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
      {placeholderProducts.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </div>
  );
}

export default ProductGrid;
