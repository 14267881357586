import React from 'react';
import Footer from '../components/Common/Footer/Footer';
import TeamGrid from '../components/TeamPage/TeamSection';

const OurTeamPage = () => {
  return (
    <div>
        <TeamGrid /> 
        <Footer />
    </div>
  );
};

export default OurTeamPage;